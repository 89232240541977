<template>

<app-page>

	<app-page-head title="Terms" :count="list.length">

		<app-page-head-search v-model="searchText" :count="list.length" />
		<app-page-head-action route="Term.New" icon="create" tooltip="Add term" />
		
	</app-page-head>

	<app-page-content :is-empty="!list.length" empty-message="No terms found.">

		<com-item v-for="item in list" :key="item.id + '/' + item.parent + '/' + item.type" :term="item" :highlight="searchText" />

	</app-page-content>

	<app-page-foot />

</app-page>

</template>

<script>

import mixList from '@/mixin/list'
import slugify from 'slugify'

export default {

	mixins: [mixList],

	components: {
		'com-item': () => import('./terms/Item')
	},

	data: function() {

		return {
			store: false
		}

	},

	computed: {

		generatedList: function() {

			var terms = []

			this.$_.each({
				category: 'categories',
				group: 'groups',
				item: 'items',
				location: 'locations'
			}, function(store, key) {

				this.$_.each(this.$store.getters[store], function(item) {

					terms.push({
						id: 'name',
						parent: item.id,
						title: slugify(item.title, {
							replacement: ' ',
							lower: true
						}),
						auto: true,
						type: this.$constants.type[key]
					})

					this.$_.each(item.terms, function(term, index) {

						terms.push({
							id: index + 1,
							parent: item.id,
							title: term,
							auto: false,
							type: this.$constants.type[key]
						})

					}.bind(this))

				}.bind(this))

			}.bind(this))

			return this.$_.sortBy(terms, function(term) {

				return term.title

			})

		}

	},

	methods: {

		search: function(value) {

			return this.$util.search(value.title, this.searchText) 

		}

	}

}

</script>

<style scoped>

</style>
